//审批标记
const _approveflag = {
    '-2': '未提请审批',
    '-1': '审核中',
    '0': '不通过',
    '1': '通过',
}

//收款标识
const _isokflag = {
	'0': '未结账',
	'1': '半结账',
	'2': '已结清'
}
const _discount = [
	{
		key: 1,
		val: '直接优惠',
	},
	{
		key: 2,
		val: '转介绍',
	},
	{
		key: 3,
		val: '3人同报名',
	}
]

const _approveflags = [
    {
        value: '-2',
        label: '未提请审批',
    },
    {
        value: '-1',
        label: '审核中',
    },
    {
        value: '0',
        label: '不通过',
    },
    {
        value: '1',
        label: '通过',
    },
]

const _passflags = [
	{
		value: '0',
		label: '不通过',
	},
	{
		value: '1',
		label: '通过',
	}
]

const _discountYear = [
				{
					key: '1',
					val: 1,
				},
				{
					key: '2',
					val: 2,
				},
				{
					key: '3',
					val: 3,
				},
				{
					key: '4',
					val: 4,
				},
				{
					key: '5',
					val: 5,
				},
]

const _collegetype = {
    1: '成考',
    3: '网络教育',
    4: '自考'
}
const _ttype = {
    1: '单选',
    2: '多选',
    3: '简答',
    4: '填空',
    5: '判断'
}

// 报名状态
const _baomingtag = [
    '跟进中',
    '学位费',
    '辅导费',
    '教材费',
    '预报名',
    '辅导加预报名',
    '教材加预报名',
    '在籍学生',
    '学生放弃报名',
    '放弃跟进'
]

//审批等级
const _approveLevel = {
    '02': '教学点负责人审核',
    '01': '总裁审核',
}
const _approveLevels = [
    {
        value: '02',
        label: '教学点负责人审核',
    },
    {
        value: '01',
        label: '总裁审核',
    },
]

//科目组
const _isAppselect = {
	'高升专':["高中起点高职高专类（不分文理）(语文、数学、外语)"],
	'专升本':[
	"专升本文史、中医类(政治、外语、大学语文)",
	"专升本艺术类(政治、外语、艺术概论)",
	"专升本理工、经管类(政治、外语、高等数学(二))",
	"专升本法学类（文史）(政治、外语、民法)",
	"专升本教育学类（文史）(政治、外语、教育理论)",
	"专升本农学类（理工）(政治、外语、生态学基础)",
	"专升本医学类（理工）(政治、外语、医学综合)",
	],
	'高升本':[
						"高中起点本科理工、体育类(语文、数学、外语、理化)",
						"高中起点本科文史、外语、艺术类(语文、数学、外语、史地)",
					],
	'中专':["语文、数学、英语"],
	'研究生':["政治、英语"]
}


//审核是否通过标记
const _isApprovePass = {
    '0': '未通过',
    '1': '通过',
}
const _isApprovePasss = [
    {
        value: '0',
        label: '未通过',
    },
    {
        value: '1',
        label: '通过',
    },
]

//项目类型
const _projectType = {
    1: '成人高考',
    2: '网络教育',
    3: '自学考试',
    4: '全日制',
}
//院校收费标准-导入用
const _projectTypes = [
    {
        key: 1,
        val: '成人高考'
    },
    {
        key: 2,
        val: '网络教育'
    },
    {
        key: 3,
        val: '自学考试'
    },
    {
        key: 4,
        val: '全日制'
    },
]

const _projectApprovals = [
	{
		key : 1,
		val: '支出登记'
	},
	{
		key: 2,
		val: '院校往来'
	}
]

const _propSelects = [
    {
        key: 'btnAdd',
        val: '增加',
    },
    {
        key: 'btnEdit',
        val: '修改',
    },
    {
        key: 'btnDel',
        val: '删除',
    },
    {
        key: 'btnVeryfy1',
        val: '教学点负责人审批',
    },
		{
		    key: 'btnPrint',
		    val: '打印 ',
		},
		{
		    key: ' btnExport',
		    val: '导出',
		},
		{
		    key: 'btnRequestApprove',
		    val: '提请审批',
		},
]



/* const _projectMap = [
    {
        key: 1,
        val: '成人高考'
    },
    {
        key: 2,
        val: '网络教育'
    },
    {
        key: 3,
        val: '自学考试'
    },
    {
        key: 4,
        val: '全日制'
    },
]
 */


// 缴费项目
const _PayCostProject = [
    {
        key: 778727,
        val: '代收学费'
    },
    {
        key: 778733,
        val: '预收教材费'
    },
    {
        key: 49849300,
        val: '学费及服务费'
    },
]

//支付类型
const _bankorcash = {
    0: '银行',
    1: '现金',
}

const _bankorcashs = [
    {
        value: '1',
        label: '现金',
    },
    {
        value: '0',
        label: '银行',
    },
]

//收款标识
const _paidType = {
    1: '已收款',
    0: '未收款'
}
const _paidTypes = [
    {
        value: '1',
        label: '已收款',
    },
    {
        value: '0',
        label: '未收款',
    },
]
//付款标识
const _payType = {
    1: '已付',
    0: '未付'
}
const _payTypes = [
    {
        value: '1',
        label: '已付',
    },
    {
        value: '0',
        label: '未付',
    },
]

//正式学生
const _formalStudent = {
    1: '正式学生',
    0: '非正式学生'
}
const _formalStudents = [
    {
        value: '1',
        label: '正式学生',
    },
    {
        value: '0',
        label: '非正式学生',
    },
]

//退费标识
const _feebackflag = {
    1: '已退费',
    0: '未退费'
}
const _feebackflags = [
    {
        value: '1',
        label: '已退费',
    },
    {
        value: '0',
        label: '未退费',
    },
]
const _isextrasubmoneyflags = [
		{
		    value: '1',
		    label: '是',
		},
		{
		    value: '0',
		    label: '否',
		},
]
//收付款状态
const _paymentState = {
    0: '未收', 1: '已收', 2: '未付', 3: '已付', 4: '坏账',
}
const _paymentStates = [
    {
        value: '0',
        label: '未收',
    },
    {
        value: '1',
        label: '已收',
    },
    {
        value: '2',
        label: '未付',
    },
    {
        value: '3',
        label: '已付',
    },
    {
        value: '4',
        label: '坏账',
    },
]

//技能培训 (项目类型)
const _tech_projectTyles = [
    {
        value: 1,
        label: '互动美语'
    },
    {
        value: 2,
        label: '会计职称'
    },
    {
        value: 3,
        label: '执业药师'
    },
    {
        value: 4,
        label: '教师资格'
    },
    {
        value: 5,
        label: '健康管理师'
    },
    {
        value: 6,
        label: '其他技能培训'
    },
]

//异动类型
const _changeTypes = [
    {
        value: 1,
        label: '休学'
    },
    {
        value: 2,
        label: '退学'
    },
    {
        value: 3,
        label: '复学'
    },
    {
        value: 4,
        label: '转专业'
    },
    {
        value: 5,
        label: '转学'
    },
    {
        value: 6,
        label: '转学转专业'
    },
    {
        value: 7,
        label: '转学习形式'
    },
]
const _changeType = {
    1: '休学',
    2: '退学',
    3: '复学',
    4: '转专业',
    5: '转学',
}

// 友情链接增加类型下拉数据
const _linkAddTypes = [
    {
        key: 1,
        val: '学习平台'
    },
    {
        key: 2,
        val: '管理平台'
    }
]
const _linkAddType = {
    1: '学习平台',
    2: '管理平台',
}

//往来类型
const _contactTypes = ["院校往来应收", "院校往来应付", "日常往来应收", "日常往来应付"]

//是否可用
const _availables = [{
    value: '1',
    label: '是',
},
{
    value: '0',
    label: '否',
}]

const _available = {
    1: '是',
    0: '否',
}

const _coursetype = {
    1: '学生',
    2: '员工',
}
const _examin = {
		1: '支出登记',
		2: '院校往来',

}

const _complaints = [{
    value: '1',
    label: '投诉',
},
{
    value: '2',
    label: '建议',
}]
const _complaint = {
    1: '投诉',
    2: '建议'
}


const _chongzhanflags = [{
    value: '0',
    label: '未冲账',
},
{
    value: '1',
    label: '已冲账',
}, {
    value: '2',
    label: '被冲账',
}]

const _chongzhanflag = {
    0: '未冲账',
    1: '已冲账',
    2: '被冲账',
}
const _typeStudent = {
    0: '渠道',
    1: '家和直招',
    2: '德立直招',
}

const _techtypeflag = {
    1: '德立',
    2: '家和',
}

const registerflags = [{
    value: 1,
    label: '已登账',
},
{
    value: 0,
    label: '未登账',
}]
const registerflag = {
    1: '已登账',
    0: '未登账'
}
const areaflag = [
{value:103,label:'0103 广州荔湾'},
{value:104,label:'0104 广州越秀'},
{value:105,label:'0105 广州海珠'},
{value:106,label:'0106 广州天河'},
{value:111,label:'0111 广州白云'},
{value:112,label:'0112 广州黄埔'},
{value:113,label:'0113 广州番禺'},
{value:114,label:'0114 广州花都'},
{value:115,label:'0115 广州南沙'},
{value:117,label:'0117 广州从化'},
{value:118,label:'0118 广州增城'},
{value:201,label:'0201 韶关市辖'},
{value:281,label:'0281 韶关乐昌'},
{value:303,label:'0303 深圳罗湖'},
{value:304,label:'0304 深圳福田'},
{value:305,label:'0305 深圳南山'},
{value:306,label:'0306 深圳宝安'},
{value:307,label:'0307 深圳龙岗'},
{value:308,label:'0308 深圳盐田'},
{value:309,label:'0309 深圳光明'},
{value:310,label:'0310 深圳坪山'},
{value:311,label:'0311 深圳龙华'},
{value:312,label:'0312 深圳大鹏'},
{value:401,label:'0401 珠海市辖'},
{value:403,label:'0403 珠海斗门'},
{value:501,label:'0501 汕头市辖'},
{value:507,label:'0507 汕头龙湖'},
{value:511,label:'0511 汕头金平'},
{value:512,label:'0512 汕头濠江'},
{value:513,label:'0513 汕头潮阳'},
{value:514,label:'0514 汕头潮南'},
{value:515,label:'0515 汕头澄海'},
{value:523,label:'0523 汕头南澳'},
{value:604,label:'0604 佛山禅城'},
{value:605,label:'0605 佛山南海'},
{value:606,label:'0606 佛山顺德'},
{value:607,label:'0607 佛山三水'},
{value:608,label:'0608 佛山高明'},
{value:701,label:'0701 江门市辖'},
{value:703,label:'0703 江门蓬江'},
{value:705,label:'0705 江门新会'},
{value:781,label:'0781 江门台山'},
{value:783,label:'0783 江门开平'},
{value:785,label:'0785 江门恩平'},
{value:801,label:'0801 湛江市辖'},
{value:802,label:'0802 湛江赤坎'},
{value:803,label:'0803 湛江霞山'},
{value:804,label:'0804 湛江坡头'},
{value:806,label:'0806 湛江开发'},
{value:811,label:'0811 湛江麻章'},
{value:823,label:'0823 湛江遂溪'},
{value:825,label:'0825 湛江徐闻'},
{value:881,label:'0881 湛江廉江'},
{value:882,label:'0882 湛江雷州'},
{value:883,label:'0883 湛江吴川'},
{value:901,label:'0901 茂名市辖'},
{value:923,label:'0923 茂名电白'},
{value:981,label:'0981 茂名高州'},
{value:982,label:'0982 茂名化州'},
{value:983,label:'0983 茂名信宜'},
{value:1202,label:'1202 肇庆端州'},
{value:1203,label:'1203 肇庆鼎湖'},
{value:1204,label:'1204 肇庆高要'},
{value:1223,label:'1223 肇庆广宁'},
{value:1224,label:'1224 肇庆怀集'},
{value:1225,label:'1225 肇庆封开'},
{value:1226,label:'1226 肇庆德庆'},
{value:1284,label:'1284 肇庆四会'},
{value:1291,label:'1291 肇庆高新'},
{value:1301,label:'1301 惠州市辖'},
{value:1302,label:'1302 惠州惠城'},
{value:1322,label:'1322 惠州博罗'},
{value:1323,label:'1323 惠州惠东'},
{value:1401,label:'1401 梅州市辖'},
{value:1521,label:'1521 汕尾海丰'},
{value:1581,label:'1581 汕尾陆丰'},
{value:1602,label:'1602 河源源城'},
{value:1621,label:'1621 河源紫金'},
{value:1622,label:'1622 河源龙川'},
{value:1623,label:'1623 河源连平'},
{value:1624,label:'1624 河源和平'},
{value:1625,label:'1625 河源东源'},
{value:1701,label:'1701 阳江市辖'},
{value:1721,label:'1721 阳江阳西'},
{value:1723,label:'1723 阳江阳东'},
{value:1781,label:'1781 阳江阳春'},
{value:1802,label:'1802 清远清城'},
{value:1823,label:'1823 清远阳山'},
{value:1881,label:'1881 清远英德'},
{value:1882,label:'1882 清远连州'},
{value:1901,label:'1901 东莞市辖'},
{value:2000,label:'2000 中山全市'},
{value:5101,label:'5101 潮州市辖'},
{value:5102,label:'5102 潮州湘桥'},
{value:5121,label:'5121 潮州潮安'},
{value:5122,label:'5122 潮州饶平'},
{value:5201,label:'5201 揭阳市辖'},
{value:5302,label:'5302 云浮云城'},
{value:5321,label:'5321 云浮新兴'},
{value:5322,label:'5322 云浮郁南'},
{value:5381,label:'5381 云浮罗定'},
{value:549,label:'549外省'},
]




export {
	_propSelects,
    _payType,
		_passflags,
    _payTypes,
    registerflags,
    registerflag,
    _collegetype,
    _ttype,
    _linkAddTypes,
    _linkAddType,
    _approveflag,
		_isokflag,
    _approveflags,
    _approveLevel,
		_discount,
    _approveLevels,
    _isApprovePass,
    _isApprovePasss,
    _projectType,
		_discountYear,
    _projectTypes,
		_projectApprovals,
    _bankorcash,
    _bankorcashs,
    _paidType,
    _paidTypes,
    _formalStudent,
    _formalStudents,
    _feebackflag,
    _feebackflags,
		_isextrasubmoneyflags,
    _paymentState,
    _paymentStates,
    _tech_projectTyles,
    _changeTypes,
    _changeType,
    _PayCostProject,
    _contactTypes,
    _availables,
		 _examin,
     _coursetype,
    _baomingtag,
    _available,
    _complaints,
    _complaint,
    _chongzhanflags,
    _chongzhanflag,
		_typeStudent,
		_techtypeflag,
		_isAppselect,
		areaflag,
}
