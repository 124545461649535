<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
					<el-form-item label="流水号:">
					  <el-input 
							v-model.trim="searchForm.id" 
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
          <el-form-item label="增补岗位:">
            <el-input 
							v-model.trim="searchForm.positionname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="教学点:">
            <el-input 
							v-model.trim="searchForm.schoolorgname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{ padding: 0 }"
      @selection-change="
        (val, multipleSelection) =>
          handleSelectionChange(val, 'multipleSelection')
      "
    >
      <el-table-column type="selection" width="55" />
			<el-table-column label="流水号" prop="id"></el-table-column>
      <el-table-column label="教学点" prop="schoolorgname" align="center" width="160px">
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.schoolorgname
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="增补岗位" prop="positionname" align="center" />
      <el-table-column
        label="申请时间"
        prop="applytime"
        align="center"
        width="110"
      >
        <template slot-scope="scope">
          {{ scope.row.applytime | formatDate("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column
        label="希望到职时间"
        prop="wantedtime"
        align="center"
        width="110"
      >
        <template slot-scope="scope">
          {{ scope.row.wantedtime | formatDate("YYYY-MM-DD") }}
        </template>
      </el-table-column>

      <el-table-column label="编制人数" prop="allcount" align="center" />
      <el-table-column label="当前人数" prop="currentcount" align="center" />
      <el-table-column label="拟招人数" prop="applycount" align="center" />
      <el-table-column label="性别要求" prop="sexrequire" align="center" />
      <el-table-column label="审批标志" align="center">
        <template slot-scope="scope">
          {{ approveflag[scope.row.approveflag] }}
        </template>
      </el-table-column>
      <el-table-column label="增补理由" prop="applyreason" align="center" />
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
			:close-on-click-modal='false'
      v-if="showDetailModal"
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>查看详情</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
        <el-form-item label="教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="部门:">
          {{ dynamicValidateForm.departorgname }}
        </el-form-item>
        <el-form-item label="填表人:">
          {{ dynamicValidateForm.applyusername }}
        </el-form-item>
        <el-form-item label="年龄:">
          {{ dynamicValidateForm.age }}
        </el-form-item>
        <el-form-item label="学历:">
          {{ dynamicValidateForm.eduval }}
        </el-form-item>
        <el-form-item label="性别要求:">
          {{ dynamicValidateForm.sexrequire }}
        </el-form-item>
        <el-form-item label="希望到职时间:">
          {{ dynamicValidateForm.wantedtime | formatDate("YYYY-MM-DD") }}
        </el-form-item>
        <el-form-item label="申请时间:">
          {{ dynamicValidateForm.applytime | formatDate("YYYY-MM-DD") }}
        </el-form-item>
        <el-form-item label="编制人数:">
          {{ dynamicValidateForm.allcount }}
        </el-form-item>
        <el-form-item label="经历:">
          {{ dynamicValidateForm.experience }}
        </el-form-item>
        <el-form-item label="当前人数:">
          {{ dynamicValidateForm.currentcount }}
        </el-form-item>
        <el-form-item label="具备技能:">
          {{ dynamicValidateForm.skill }}
        </el-form-item>
        <el-form-item label="拟招人数:">
          {{ dynamicValidateForm.applycount }}
        </el-form-item>
        <el-form-item label="增补岗位:">
          {{ dynamicValidateForm.positionname }}
        </el-form-item>
        <el-form-item label="申请增补理由:">
          {{ dynamicValidateForm.applyreason }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      width="60%"
			:close-on-click-modal='false'
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
			:title="this.dialogTitle"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="140px"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门" prop="departorgname">
          <el-select
            v-model="dynamicValidateForm.departorgname"
            @change="onDepartmentChange"
          >
            <el-option
              v-for="item in departmentList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="岗位" prop="positionname">
          <el-select
            v-model="dynamicValidateForm.positionname"
            @change="onPositionnameChange"
          >
            <el-option
              v-for="item in jobList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="学历" prop="edukey">
          <el-select v-model="dynamicValidateForm.edukey">
            <el-option
              v-for="item in eduList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="性别要求" prop="sexrequire">
          <el-select v-model="dynamicValidateForm.sexrequire">
            <el-option value="男" />
            <el-option value="女" />
            <el-option value="不限" />
          </el-select>
        </el-form-item>
        <el-form-item label="编制人数" prop="allcount">
          <el-input v-model.trim="dynamicValidateForm.allcount" clearable/>
        </el-form-item>
        <el-form-item label="当前人数" prop="currentcount">
          <el-input v-model.trim="dynamicValidateForm.currentcount" clearable/>
        </el-form-item>
        <el-form-item label="拟招人数" prop="applycount">
          <el-input v-model.trim="dynamicValidateForm.applycount" clearable/>
        </el-form-item>
        <el-form-item label="申请增补理由" prop="applyreason">
          <el-input v-model.trim="dynamicValidateForm.applyreason" clearable/>
        </el-form-item>
        <el-form-item label="希望到职时间" prop="wantedtime">
          <el-date-picker
            v-model="dynamicValidateForm.wantedtime"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 审批 -->
    <el-dialog
      class="btn-2b5a95"
      title="审批："
			v-dialogDrag
      :visible.sync="showApprovalModal"
			:close-on-click-modal='false'
			v-if="showApprovalModal"
      append-to-body
      width="35%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        
      >
        <el-form-item
          label="审核是否通过："
          prop="passflag"
          :rules="[
            {
              required: true,
              message: '必填字段',
              trigger: 'blur',
            },
          ]"
        >
          <el-select
            v-model="dynamicValidateForm.passflag"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in [
                { value: 1, label: '通过' },
                { value: 0, label: '不通过' },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="审批意见："
          prop="approvedesc"
          :rules="[
            {
              required: true,
              message: '必填字段',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            type="textarea"
            v-model.trim="dynamicValidateForm.approvedesc"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onApprovalClick('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!--上传应聘资料-->
    <el-dialog
      class="btn-2b5a95"
      width="800px"
			v-dialogDrag
      title="上传应聘资料："
      :visible.sync="showPersonModal"
			:close-on-click-modal='false'
			v-if="showPersonModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="分公司：">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="教学点：">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            :disabled="!$store.state.login.isMaxAuth"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名：" prop="username">
          <el-input v-model.trim="dynamicValidateForm.username" clearable/>
        </el-form-item>
        <el-form-item label="性别：" prop="sexkey">
          <el-select v-model="dynamicValidateForm.sexkey">
            <el-option label="男" value="1" />
            <el-option label="女" value="2" />
            <el-option label="不限" value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="电话：" prop="contactphone">
          <el-input v-model.trim="dynamicValidateForm.contactphone" maxlength="11" clearable/>
        </el-form-item>
        <el-form-item label="毕业院校：" prop="graduateschool">
          <el-input v-model.trim="dynamicValidateForm.graduateschool" clearable/>
        </el-form-item>
        <el-form-item label="专业：" prop="major">
          <el-input v-model.trim="dynamicValidateForm.major" clearable/>
        </el-form-item>
        <el-form-item label="上传简历：" prop="attachurl">
          <el-input v-model="dynamicValidateForm.attachurl" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :before-upload="beforeUpload"
            :http-request="uploadFile"
            :show-file-list="false"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
        <el-form-item label="学历：" prop="edukey">
          <el-select v-model="dynamicValidateForm.edukey" clearable>
            <el-option
              v-for="item in eduList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitPersonForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 应聘记录 -->
    <el-dialog
      width="80%"
			v-dialogDrag
      title="应聘记录："
      :visible.sync="showViewPersonModal"
			:close-on-click-modal='false'
			v-if="showViewPersonModal"
      append-to-body
    >
      <!-- 搜索 -->
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="layer_2_search"
						@submit.native.prevent
          >
            <el-form-item label="流水号:" prop="id">
              <el-input 
								v-model.trim="layer_2_search.id" 
								@keyup.enter.native="viewPersonRoute(indexRow)"
								clearable />
            </el-form-item>
            <el-form-item label="姓名:" prop="username">
              <el-input 
								v-model.trim="layer_2_search.username" 
								@keyup.enter.native="viewPersonRoute(indexRow)"
								clearable />
            </el-form-item>
            <el-form-item label="性别要求" prop="sexval">
              <el-select 
								v-model="layer_2_search.sexval" 
								@keyup.enter.native="viewPersonRoute(indexRow)"
								clearable>
                <el-option label="男" value="男" />
                <el-option label="女" value="女" />
                <el-option label="不限" :value="null" />
              </el-select>
            </el-form-item>
            <el-form-item label="联系电话:" prop="contactphone">
              <el-input 
								v-model.trim="layer_2_search.contactphone"
								@keyup.enter.native="viewPersonRoute(indexRow)" 
								clearable />
            </el-form-item>
            <el-form-item label="职位:" prop="positionname">
              <el-input 
								v-model.trim="layer_2_search.positionname" 
								@keyup.enter.native="viewPersonRoute(indexRow)"
								clearable />
            </el-form-item>
            <el-form-item label="学历:" prop="eduval">
              <el-input 
								v-model.trim="layer_2_search.eduval" 
								@keyup.enter.native="viewPersonRoute(indexRow)"
								clearable />
            </el-form-item>
            <el-form-item label="毕业学校:" prop="graduateschool">
              <el-input 
								v-model.trim="layer_2_search.graduateschool" 
								@keyup.enter.native="viewPersonRoute(indexRow)"
								clearable />
            </el-form-item>
            <el-form-item label="专业:" prop="major">
              <el-input 
								v-model.trim="layer_2_search.major" 
								@keyup.enter.native="viewPersonRoute(indexRow)"
								clearable />
            </el-form-item>
            <el-form-item label="教学点:" prop="schoolorgname">
              <el-input 
								v-model.trim="layer_2_search.schoolorgname" 
								@keyup.enter.native="viewPersonRoute(indexRow)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="viewPersonRoute(indexRow)"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <!-- 按钮操作 -->
      <div
        class="Btn-w"
        v-if="tablePersonRes.buttonlist && tablePersonRes.buttonlist.length"
      >
        <div class="btn-l eaf4ff-btn">
          <el-button type="primary" @click="onRecordAdd">增加</el-button>
          <el-button type="primary" @click="onRecordEdit">修改</el-button>
          <el-button type="primary" @click="onRecordDel">删除</el-button>
          <el-button type="primary" @click="onRecordRefresh">刷新</el-button>
        </div>
        <div class="Btn-extend"></div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="tablePersonRes.list"
        stripe
        border
				:key="key"	
        ref="multipleTable"
        style="width: 100%"
        :cell-style="{ padding: 0 }"
        @selection-change="
          (val, multipleSelection) =>
            handleSelectionChange(val, 'multipleSelection')
        "
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="姓名" prop="username" align="center" />
        <el-table-column label="性别" prop="sexval" align="center" />
        <el-table-column label="联系电话" prop="contactphone" align="center" />
        <el-table-column label="职位" prop="positionname" align="center" />
        <el-table-column label="学历" prop="eduval" align="center" />
        <el-table-column
          label="毕业学校"
          prop="graduateschool"
          align="center"
        />
        <el-table-column label="专业" prop="major" align="center" />
        <el-table-column label="教学点" prop="schoolorgname" align="center" />
        <el-table-column
          label="操作"
          prop="schoolorgname"
          align="center"
          width="300"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="btnJianli(scope.row)"
              >下载人才简历</el-button
            >
            <el-button type="text" @click="btnInterview(scope.row)"
              >预约面试</el-button
            >
            <el-button
              type="text"
              @click="
                (url, modal, params, row, prop, page) =>
                  onSelectData(
                    _userinterview_list,
                    'showUserinterview',
                    { personid: scope.row.id },
                    scope.row,
                    'userinterview_select',
                    'userinterview_page'
                  )
              "
              >查看预约信息</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="personPageinfo.pageindex"
        :page-size="personPageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="tablePersonRes.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 查看预约信息 - 列表 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
      title="查看预约信息："
      :visible.sync="showUserinterview"
			:close-on-click-modal='false'
			v-if="showUserinterview"
      append-to-body
    >
      <el-button type="primary" @click="onUserinterviewAdd">增加</el-button>
      <el-button type="primary" @click="onUserinterviewEdit">修改</el-button>
      <el-button type="primary" @click="onUserinterviewDel">删除</el-button>
      <el-button type="primary" @click="onUserinterviewFlag"
        >设置面试结果</el-button
      >
      <el-table
        :data="userinterview_select.list"
        style="width: 100%"
        ref="moneyTable"
				:key="key"	
        @selection-change="
          (val, multipleSelection) =>
            handleSelectionChange(val, 'lowest_multipleSelection')
        "
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="用户名称"
          prop="username"
          align="center"
        ></el-table-column>
        <el-table-column
          label="联系电话"
          prop="contactphone"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          label="面试类别"
          prop="interviewtypeval"
          align="center"
        ></el-table-column>
        <el-table-column
          label="面试官姓名"
          prop="interviewusername"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="面试时间"
          prop="interviewtime"
          align="center"
          width="160"
        >
          <template slot-scope="scope">
            {{ scope.row.interviewtime | formatDate("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column label="是否通过" prop="remark" align="center">
          <template slot-scope="scope">
            {{ scope.row.flag === 1 ? "通过" : "未通过" }}
          </template>
        </el-table-column>
        <el-table-column
          label="备注"
          prop="remark"
          align="center"
        ></el-table-column>
        <el-table-column
          label="教学点"
          prop="schoolorgname"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data, prop, page) =>
            handleSelectChange(
              val,
              { personid: itemRow.id },
              'userinterview_select',
              'userinterview_page'
            )
        "
        :current-page="userinterview_page.pageindex"
        :page-size="userinterview_page.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="userinterview_select.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 查看预约信息 - 增加，修改 -->
    <el-dialog
      class="btn-2b5a95"
      width="600px"
			v-dialogDrag
      :title="dialogTitle"
      :visible.sync="showUserinterAdd"
			:close-on-click-modal='false'
			v-if="showUserinterAdd"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="人才：" prop="username">
          <el-input v-model="dynamicValidateForm.username" disabled />
        </el-form-item>
        <el-form-item label="联系电话：" prop="contactphone">
          <el-input v-model="dynamicValidateForm.contactphone" disabled />
        </el-form-item>
        <el-form-item label="面试时间：" prop="interviewtime">
          <el-date-picker
            v-model="dynamicValidateForm.interviewtime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="面试官：" prop="interviewusername">
          <el-input v-model="dynamicValidateForm.interviewusername" disabled />
          <el-button
            class="select-btn"
            type="primary"
            @click="
              (url, modal, params, row, prop, page) =>
                onSelectData(
                  user_dialogresult,
                  'showUserModal',
                  {
                    schoolorgid: indexRow.schoolorgid,
                  },
                  itemRow,
                  'selectTable',
                  'selectPageInfo'
                )
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="面试类别：" prop="interviewtypekey">
          <el-select
            v-model="dynamicValidateForm.interviewtypekey"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in interviewCate"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitUserinterSave('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 查看预约信息 - 设置 -->
    <el-dialog
      class="btn-2b5a95"
      width="600px"
			v-dialogDrag
      title="审核："
      :visible.sync="showUserinterFlag"
			:close-on-click-modal='false'
			v-if="showUserinterFlag"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="是否通过：" prop="flag">
          <el-select
            v-model="dynamicValidateForm.flag"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in isApprovePasss"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input type="textarea" v-model="dynamicValidateForm.remark" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitUserinterFlag('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 预约面试 -->
    <el-dialog
      class="btn-2b5a95"
      title="预约面试："
			v-dialogDrag
      :visible.sync="showInterviewModal"
			:close-on-click-modal='false'
			v-if="showInterviewModal"
      append-to-body
      width="35%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        
        :rules="rules"
      >
        <el-form-item label="人才：" prop="username">
          <el-input v-model="dynamicValidateForm.username" disabled />
        </el-form-item>
        <el-form-item label="联系电话：" prop="contactphone">
          <el-input v-model="dynamicValidateForm.contactphone" disabled />
        </el-form-item>
        <el-form-item label="面试官：" prop="interviewusername">
          <el-input v-model="dynamicValidateForm.interviewusername" disabled />
          <el-button
            class="select-btn"
            type="primary"
            @click="
              (url, modal, params, row, prop, page) =>
                onSelectData(
                  user_dialogresult,
                  'showUserModal',
                  {
                    schoolorgid: indexRow.schoolorgid,
                  },
                  itemRow,
                  'selectTable',
                  'selectPageInfo'
                )
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="面试时间：" prop="interviewtime">
          <el-date-picker
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="dynamicValidateForm.interviewtime"
            placeholder="选择日期时间"
          />
        </el-form-item>
        <el-form-item label="面试类别：" prop="interviewtypekey">
          <el-select v-model="dynamicValidateForm.interviewtypekey">
            <el-option value="01" label="初试"></el-option>
            <el-option value="02" label="复试"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input v-model.trim="dynamicValidateForm.remark" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAppointmentForm('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择面试官 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择面试官："
      :visible.sync="showUserModal"
			:close-on-click-modal='false'
			v-if="showUserModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmUser">确定</el-button>

<div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="真实姓名:" prop="realname">
              <el-input 
								v-model.trim="selectSearchForm.realname" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(selectSearchForm, {
                  schoolorgid: dynamicValidateForm.schoolorgid,
                }),
                itemRow,
                'selectTable',
                'selectPageInfo'
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(selectSearchForm, {
                  schoolorgid: dynamicValidateForm.schoolorgid,
                }),
                itemRow,
                'selectTable',
                'selectPageInfo'
              )
            "
            >搜索</el-button
          >
         <el-button type="primary" @click="showQuery">重置</el-button>
        </div>
      </div>


      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				:key="key"	
        @selection-change="
          (val, multipleSelection) =>
            handleSelectionChange(val, 'multipleSelection')
        "
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
        ></el-table-column>
        <el-table-column
          label="姓名"
          prop="realname"
          align="center"
        ></el-table-column>
        <el-table-column
          label="性别"
          prop="sexval"
          align="center"
        ></el-table-column>
        <el-table-column
          label="教学点"
          prop="schoolorgname"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data, prop) =>
            handleSelectChange(
              val,
              { schoolorgid: indexRow.schoolorgid },
              'selectTable',
              'selectPageInfo'
            )
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil ? selectTable.paginationutil.totalcount : 0
        "
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  userapply_list,
  userapply_delete,
  userapply_save,
  userapply_input,
  person_list,
  person_input,
  person_save,
  person_delete,
  userinterview_save,
  userinterview_list,
  userinterview_delete,
  userinterview_flag,
  userapply_approvesave,
  userapply_requestapprove,
} from "@/request/api/allChildrenProject";
import {
  _fileupload_save,
  _filedownload_save,
  _allCompany,
  _allSchool,
  _orgposition_combox,
  dic_combox,
  _person_dialog,
} from "@/request/api/public";
import {
  _approveflag,
  _isApprovePass,
  _isApprovePasss,
} from "@/assets/js/filedValueFlag";
import { _user_dialogresult } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "building",
  components: {},
  props: {
    user_dialogresult: {
      default: () => _user_dialogresult,
    },

    _userinterview_list: {
      default: () => userinterview_list,
    },
    person_dialog: {
      default: () => _person_dialog,
    },
    isApprovePass: {
      default: () => _isApprovePass,
    },
    isApprovePasss: {
      default: () => _isApprovePasss,
    },
  },
  data() {
    return {
      itemRow: null, //表格项
      indexRow: null, //首表格项
      searchForm: { id: null }, //index - 搜索

      layer_2_search: {},
			key: '',
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      jobList: [], // 岗位
      departmentList: [], // 部门
      showApprovalModal: false, //审批框
      showPersonModal: false, // 上传应聘资料
      showInterviewModal: false, // 预约面试
      showViewPersonModal: false, // 应聘记录
      tablePersonRes: {}, // 应聘记录表格
      isPersonShow: false, // 上传应聘教学点分公司
      showUserModal: false, // 面试官
      personPageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询

      selectTable: {},
      selectTableUrl: null,
      selectSchoolorgid: null,
      selectSearchForm: {},
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      }, // 选项

      approveflag: _approveflag, // 审批标志
      showAddModal: false, // 添加
      eduList: [], // 学历
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        companyorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        departorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        positionname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        edukey: [{ trigger: "blur", message: "必填字段", required: true }],
        sexrequire: [{ trigger: "blur", message: "必填字段", required: true }],
        allcount: [{ trigger: "blur", message: "必填字段", required: true }],
        currentcount: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        applycount: [{ trigger: "blur", message: "必填字段", required: true }],
        applyreason: [{ trigger: "blur", message: "必填字段", required: true }],
        wantedtime: [{ trigger: "blur", message: "必填字段", required: true }],
        username: [{ trigger: "blur", message: "必填字段", required: true }],
        sexkey: [{ trigger: "blur", message: "必填字段", required: true }],
        contactphone: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        attachurl: [{ trigger: "blur", message: "必填字段", required: true }],
        interviewtime: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        interviewusername: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        interviewtypekey: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        flag: [{ trigger: "blur", message: "必填字段", required: true }],
        remark: [{ trigger: "blur", message: "必填字段", required: false }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      fds: new FormData(),

      //查看预约信息
      userinterview_select: {}, //数据
      userinterview_page: {
        //分页
        pageindex: 1,
        pagesize: 10,
      },
      showUserinterview: false, //- 列表
      showUserinterAdd: false, // - 增加，修改
      showUserinterDel: false, // - 删除
      showUserinterFlag: false, // - 设置
      interviewCate: [
        {
          value: "01",
          label: "初试",
        },
        {
          value: "02",
          label: "复试",
        },
      ], //面试类别
      lowest_multipleSelection: [], //选中的表格项
    };
  },
  created() {
    this.searchForm.id = this.$route.params.id;
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
    this.dic_combox({
      list: "eduList",
      typecode: "edu",
      that: this,
    });
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", [
      "dic_combox",
      "org_combox",
      "org_comboxchild",
    ]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].schoolorgid = "";
      this[form].departorgname = "";
      this[form].positionname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
      this.getDepartMentList(this[form].schoolorgid);
      this.getJobsList();
    },
    //改变教学点 change
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
      this[form].departorgname = "";
      this.getDepartMentList(val);
    },
    //获取所属部门 api
    getDepartMentList(schoolid) {
      myRequest({
        url: _allSchool,
        data: {
          orgid: schoolid,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.departmentList = res.data.data.list;
        }
      });
    },
    //所属部门 change
    onDepartmentChange(val) {
      this.dynamicValidateForm.departorgid = val;
      this.dynamicValidateForm.positionname = "";
      this.dynamicValidateForm.targetpositionname = "";
      this.getJobsList(val);
    },
    //工作岗位 api
    getJobsList(departmentid) {
      myRequest({
        url: _orgposition_combox,
        data: {
          orgid: departmentid,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.jobList = res.data.data.list;
        }
      });
    },
    //岗位 change
    onPositionnameChange(val) {
      this.dynamicValidateForm.positionid = val;
      this.dynamicValidateForm.targetpositionid = val;
    },
		reset() {
			this.searchForm = {
				id: null,
				positionname: null,
				schoolorgname: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
		selectQuery() {
			this.layer_2_search = {
				id: null,
				username: null,
				sexval: null,
				contactphone: null,
				positionname: null,
				eduval: null,
				graduateschool: null,
				major: null,
				schoolorgname: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.viewPersonRoute(this.indexRow);
		},
		showColse() {
			this.selectSearchForm = {
				realname: null
			}
		},
		showQuery() {
			this.showColse();
			this.onSelectData(
			  this.selectTableUrl,
			  null,
			  {
			    schoolorgid: this.dynamicValidateForm.schoolorgid,
			  },
			  this.itemRow,
			  'selectTable',
			  'selectPageInfo'
			);
		},
    //列表查询 api
    getDataList() {
      myRequest({
        url: userapply_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    // 查看详情 click
    btnShowDetail(row) {
      this.dynamicValidateForm = JSON.parse(JSON.stringify(row));
      this.showDetailModal = true;
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //选中表格数据改变时
    handleSelectionChange(val, prop) {
      this[prop] = val;
    },
    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        type: "员工团建",
        url: null,
        dates: null,
        positionname: null,
        departorgname: null,
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
      };
      this.$set(
        this.dynamicValidateForm,
        "companyorgname",
        this.$store.state.login.userinfo.companyorgname
      );
      this.$set(
        this.dynamicValidateForm,
        "schoolorgname",
        this.$store.state.login.userinfo.schoolorgname
      );
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.getDepartMentList(this.dynamicValidateForm.schoolorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: userapply_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.vuserapply;
          this.dynamicValidateForm.wantedtime = formatDare(
            this.dynamicValidateForm.wantedtime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          this.getDepartMentList(this.dynamicValidateForm.schoolorgid);
          this.getJobsList(this.dynamicValidateForm.departorgid);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
          this.dynamicValidateForm.applytime = formatDare(
            this.dynamicValidateForm.applytime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.showAddModal = true;
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: userapply_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: userapply_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //提请审批 click
    btnRequestApprove() {
      selectCheck(this.multipleSelection, "提请审批", true, () => {
        confirmCallBack({
          title: "提示",
          content: "确定提请审批吗？",
          success: () => {
            this.reqApproval(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //提请审批 api
    reqApproval(ids) {
      myRequest(
        {
          method: "post",
          url: userapply_requestapprove,
          data: this.$qs.stringify(
            {
              ids: ids,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //审批 click
    btnApproval() {
      selectCheck(this.multipleSelection, "审批", true, () => {
        this.approvalRoute();
      });
    },
    //审批 click
    approvalRoute() {
      this.dynamicValidateForm = {};
      this.showApprovalModal = true;
    },
    //审批保存 click
    onApprovalClick(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              method: "post",
              url: userapply_approvesave,
              data: this.$qs.stringify(
                {
                  ids: this.multipleSelection.map((i) => i.id),
                  leadertype: "05",
                  passflag: this.dynamicValidateForm.passflag,
                  approvedesc: this.dynamicValidateForm.approvedesc,
                },
                {
                  arrayFormat: "repeat",
                }
              ),
            },
            {
              that: this,
              isRefTableList: true,
              modal: "showApprovalModal",
            }
          );
        }
      });
    },
    // 上传应聘资料 click
    btnPerson() {
      selectCheck(this.multipleSelection, "上传应聘资料", false, () => {
        this.indexRow = this.multipleSelection[0];
        this.personRoutePage();
      });
    },
    // 上传应聘资料 api
    personRoutePage() {
      this.dynamicValidateForm = {
        attachurl: null,
        applyid: this.multipleSelection[0].id,
        schoolorgid: this.multipleSelection[0].schoolorgid,
        companyorgid: this.multipleSelection[0].companyorgid,
        companyorgname: this.multipleSelection[0].companyorgname,
        schoolorgname: this.multipleSelection[0].schoolorgname,
      };
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showPersonModal = true;
    },
    //上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile() {
      myRequest({
        method: "post",
        url: _fileupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.fds = new FormData();
          this.dynamicValidateForm.attachurl = res.data.url;
        }
      });
    },
    // 上传应聘资料保存 click api
    submitPersonForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest({
            url: person_save,
            data: this.dynamicValidateForm,
          }).then((res) => {
            if (res.data.code === "200") {
              this.viewPersonRoute(this.indexRow);
              this.showPersonModal = false;
            }
          });
        }
      });
    },

    // 应聘记录-列表 click
    btnViewPerson() {
      selectCheck(this.multipleSelection, "查看应聘记录", false, () => {
        this.indexRow = this.multipleSelection[0];
        this.viewPersonRoute(this.indexRow);
      });
    },
    // 应聘记录-列表 api
    viewPersonRoute(item) {
      myRequest({
        url: person_list,
        data: Object.assign(this.layer_2_search, {
          applyid: item.id,
        }),
      }).then((res) => {
        if (res.data.code == "200") {
          this.showViewPersonModal = true;
          this.tablePersonRes = res.data.data;
        }
      });
    },
    // 应聘记录-增加 click
    onRecordAdd() {
      this.dialogTitle = "增加：";
      this.dynamicValidateForm = {
        attachurl: null,
        applyid: this.indexRow.id,
        schoolorgid: this.indexRow.schoolorgid,
        companyorgid: this.indexRow.companyorgid,
        companyorgname: this.indexRow.companyorgname,
        schoolorgname: this.indexRow.schoolorgname,
      };
      this.showPersonModal = true;
    },
    // 应聘记录-修改 click
    onRecordEdit() {
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editPersonRoute(this.multipleSelection[0].id);
      });
    },
    // 应聘记录-修改 api
    editPersonRoute(id) {
      myRequest({
        url: person_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code == "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.dynamicValidateForm = res.data.data.vperson;
          this.showPersonModal = true;
        }
      });
    },
    // 应聘记录-删除click
    onRecordDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delPersonTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    // 应聘记录-刷新
    onRecordRefresh() {
      this.viewPersonRoute(this.indexRow);
    },
    // 应聘记录-删除 api
    delPersonTableItem(idArr) {
      myRequest({
        method: "post",
        url: person_delete,
        data: this.$qs.stringify(
          {
            ids: idArr,
          },
          {
            arrayFormat: "repeat",
          }
        ),
      }).then((res) => {
        if (res.data.code == "200") {
          this.viewPersonRoute(this.indexRow);
        }
      });
    },

    //下载人才简历 click
    btnJianli(row) {
      location.href =
        this.$url.Freemarker + _filedownload_save + "?fileurl=" + row.attachurl;
    },
    // 预约面试 click
    btnInterview(row) {
      this.dynamicValidateForm = {
        personid: row.id,
        username: row.username,
        contactphone: row.contactphone,
      };
      this.showInterviewModal = true;
    },
    // 预约面试 api
    submitAppointmentForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest({
            url: userinterview_save,
            data: this.dynamicValidateForm,
          }).then((res) => {
            if (res.data.code == "200") {
              this.showInterviewModal = false;
              this.viewPersonRoute(this.indexRow);
            }
          });
        }
      });
    },

    //查看预约信息-增加 click
    onUserinterviewAdd() {
      this.dialogTitle = "增加：";
      this.dynamicValidateForm = {
        username: this.itemRow.username,
        personid: this.itemRow.id,
        schoolorgid: this.itemRow.schoolorgid,
        companyorgid: this.itemRow.companyorgid,
        contactphone: this.itemRow.contactphone,
      };
      this.showUserinterAdd = true;
    },
    //查看预约信息-修改 click
    onUserinterviewEdit() {
      this.dialogTitle = "修改：";
      selectCheck(this.lowest_multipleSelection, "修改", false, () => {
        this.dynamicValidateForm = JSON.parse(
          JSON.stringify(this.lowest_multipleSelection[0])
        );
        this.$set(
          this.dynamicValidateForm,
          "interviewtime",
          formatDare(
            this.dynamicValidateForm.interviewtime,
            "YYYY-MM-DD HH:mm:ss"
          )
        );
        this.showUserinterAdd = true;
      });
    },
    //查看预约信息-删除 click
    onUserinterviewDel() {
      selectCheck(this.lowest_multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delUserinterview(
              this.lowest_multipleSelection.map((i) => i.id)
            );
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //查看预约信息-设置 click
    onUserinterviewFlag() {
      selectCheck(this.lowest_multipleSelection, "操作", false, () => {
        this.dynamicValidateForm = {
          id: this.lowest_multipleSelection[0].id,
        };
        this.showUserinterFlag = true;
      });
    },
    //查看预约信息-增加，修改提交 click api
    submitUserinterSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest({
            url: userinterview_save,
            data: this.dynamicValidateForm,
          }).then((res) => {
            if (res.data.code === "200") {
              this.onSelectData(
                this._userinterview_list,
                null,
                { personid: this.itemRow.id },
                this.itemRow,
                "userinterview_select",
                "userinterview_page"
              );
              this.showUserinterAdd = false;
            }
          });
        }
      });
    },
    //查看预约信息-删除 api
    delUserinterview(idArr) {
      myRequest({
        method: "post",
        url: userinterview_delete,
        data: this.$qs.stringify(
          {
            ids: idArr,
          },
          {
            arrayFormat: "repeat",
          }
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.onSelectData(
            this._userinterview_list,
            null,
            { personid: this.itemRow.id },
            this.itemRow,
            "userinterview_select",
            "userinterview_page"
          );
        }
      });
    },
    //查看预约信息-设置提交 click api
    submitUserinterFlag(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest({
            url: userinterview_flag,
            data: this.dynamicValidateForm,
          }).then((res) => {
            if (res.data.code === "200") {
              this.onSelectData(
                this._userinterview_list,
                null,
                { personid: this.itemRow.id },
                this.itemRow,
                "userinterview_select",
                "userinterview_page"
              );
              this.showUserinterFlag = false;
            }
          });
        }
      });
    },

    //切换选项表格页
    handleSelectChange(val, data, prop, page) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(
        this.selectTableUrl,
        null,
        data,
        this.itemRow,
        prop,
        page
      );
			this.onCloseSelect();
    },
    
    //选项表格 api
    onSelectData(url, modal, data, row, prop, page) {
      this.itemRow = row;
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this[page]),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this[prop] = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data,row,prop,page){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data,row,prop,page)
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },
    //确认面试官 click
    onConfirmUser() {
      selectCheck(this.multipleSelection, "确认面试官", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "interviewusername",
          this.multipleSelection[0].realname
        );
        this.dynamicValidateForm.interviewuserid = this.multipleSelection[0].id;
        this.showUserModal = false;
				this.showColse();
				this.multipleSelection = [];
      });
    },
  },
};
</script>

<style  lang="scss">
</style>