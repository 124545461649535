import { mapActions } from "vuex";

import { getElementStyle, getSession, getElement, debounce   } from '@/utils/util'
const part = {
    
    watch: {
        '$store.state.global.isBtnExtend_1': function (nVal, oVal) {
            this.$store.state.global.BtnExtendText_1 = nVal ? '收起' : '展开'
        },

        '$store.state.global.isSearchExtend_1': function (nVal, oVal) {
            this.$store.state.global.searchExtendText_1 = nVal ? '收起' : '展开'
        },

        '$store.state.global.isBtnExtend_2': function (nVal, oVal) {
            this.$store.state.global.BtnExtendText_2 = nVal ? '收起' : '展开'
        },

        '$store.state.global.isSearchExtend_2': function (nVal, oVal) {
            this.$store.state.global.searchExtendText_2 = nVal ? '收起' : '展开'
        },

    },

    methods: {
        ...mapActions("global", [
            "extendCondition",
            "onExtendClick",
        ]),
        ...mapActions("tab", [
            "addTab",
        ]),

        //初始化当前tab项，并匹配侧边栏和tab
        initCurrentTab() {
            this.addTab({
                tabId: getSession('thirdMenuId'),
                tabTitleName: this.$route.meta.title,
                tabRouteName: this.$route.name,
                closable:this.$route.meta.closable
            })
        },


        //是否显示 - index-1 - 搜索 - 展开按钮
        _index_search_extend(){
            if(!getElement('.search-form')) return
            let itemsw = 0
            const lw = getElementStyle('.search-form .l-w','width')
            const items = getElement('.search-form .l-w .el-form')?.children
            if(items?.length){
                for(let i =0;i<=items.length;i++){
                    if(items[i]){
                        itemsw+=items[i].getBoundingClientRect().width 
                    }
                }
                this.$store.state.global.searchExtend_1= lw < itemsw
            }
            this.$store.state.global.searchFormHeight_1 = getElementStyle('.search-form .el-form-item','height')
        },

        //是否显示 - index-1 - 操作 - 展开按钮
        _index_Btn_extend(){
            if(!getElement('.Btn-w')) return
            let itemsw = 0
            const blw = getElementStyle('.Btn-w .btn-l','width')
            const items = getElement('.Btn-w .btn-l')?.children
            if(items?.length){
                for(let i =0;i<=items.length;i++){
                    if(items[i]){
                        itemsw+=items[i].getBoundingClientRect().width + 20 //20：表示每个按钮的间距
                    }
                }
                this.$store.state.global.btnExtend_1 = blw < itemsw
            }
            this.$store.state.global.BtnHeight_1 = getElementStyle('.btn-l .el-button','height')
        },


        //是否显示 - index-2 - 搜索 - 展开按钮
        _index_2_search_extend(){
            if(!getElement('.index_2')) return
            let itemsw = 0
            const lw = getElementStyle('.index_2 .l-w','width')
            const items = getElement('.index_2 .l-w .el-form')?.children
            if(items?.length){
                for(let i =0;i<=items.length;i++){
                    if(items[i]){
                        itemsw+=items[i].getBoundingClientRect().width  
                    }
                }
                this.$store.state.global.searchExtend_2= lw < itemsw
            }
            this.$store.state.global.searchFormHeight_2 = getElementStyle('.index_2 .el-form-item','height')
        },
    },

    updated() {
        debounce(() => {
            this._index_search_extend()
            this._index_Btn_extend()
            this.initCurrentTab()
            this._index_2_search_extend()
        }, 400)()
    },
}


export {
    part
}